<template>
  <div class="page-mengjing">
    <!-- <top-cover /> -->
    <!-- <toc :items="tocItems" /> -->
    <top-cover :option="{title:' ',cover:$hwcdnLink('imagehost/vrhome_topcover/store.jpg')}" />

    <main>
      <section v-for="(A,a) in tocItems" :key="a">

        <!-- 超级品牌 -->
        <section class="section a" v-if="a===0">
          <h2 :id="A.el">{{A.label}}</h2>

          <p class="intro">“梦境世界”是全国性VR馆超级品牌，致力于打造集虚拟现实，娱乐，休闲为一体的线下VR娱乐综合场馆。凭借两百多家VR体验馆经营管理经验，包括设备支持，游戏支持，技术支持，售后支持，软件支持等自身优势，全方位支持合作VR体验店运营、赚钱！现在，“梦境世界”面向全国诚招合作伙伴，共同发展。</p>
          <section class="items">
            <div class="item" v-for="(A,a) in sectionA.items" :key="a">
              <img class="img" :src="A.img" :alt="A.title">
              <div class="box" :style="{backgroundColor: A.bgColor}">
                <h3 class="title" :style="{color:A.titleColor||'unset'}">{{A.title}}</h3>
                <p class="content" v-html="A.content" :style="{color:A.contentColor||'unset'}"></p>
              </div>
            </div>
          </section>
        </section>

        <!-- 合作案例 -->
        <section class="section b" v-if="a===1">
          <h2 :id="A.el">{{A.label}}</h2>

          <section class="form-box">
            <span class="title">梦境世界VR（体育路店）</span>
            <img class="form" src="@/assets/store/form_1@2x.png" alt="梦境世界VR（体育路店）">
            <span class="title">梦境世界VR（双子塔店）</span>
            <img class="form" src="@/assets/store/form_2@2x.png" alt="梦境世界VR（双子塔店）">
          </section>

          <section class="environment-box">
            <img class="environment" v-for="a in 5" :key="a" :src="$hwcdnLink(`imagehost/vrhome_store_environment/environment_${a}.png`)">
            <!-- <img class="environment" src="@/assets/store/environment_1.png">
            <img class="environment" src="@/assets/store/environment_2.png">
            <img class="environment" src="@/assets/store/environment_3.png">
            <img class="environment" src="@/assets/store/environment_4.png">
            <img class="environment" src="@/assets/store/environment_5.png"> -->
          </section>
        </section>

        <!-- 支持保障 -->
        <section class="section c" v-if="a===2">
          <h2 :id="A.el">{{A.label}}</h2>

          <section class="tabs">
            <div class="item" v-for="(A,a) in sectionC.items" :key="a">
              <div class="wrapper">
                <div class="block a">
                  <div class="icon-box">
                    <i class="iconfont" :class="[A.icon]" :style="{color:A.iconColor}"></i>
                  </div>
                  <h3 class="label">{{A.label}}</h3>
                </div>
                <div class="block b">
                  <p class="content" v-html="A.content"></p>
                </div>
              </div>
            </div>
          </section>

          <h3 class="games-title">海量精品内容，持续不断更新</h3>
          <vue-marquee class="games-box" direction="left" :showProgress="false">
            <vue-marquee-slide class="game-item" v-for="(A,a) in sectionC.games" :key="a">
              <img class="img" :src="$hwcdnLink(`imagehost/vrhome_store_games/${A}`)">
            </vue-marquee-slide>
          </vue-marquee>
          <!-- <div class="games-box"></div> -->
        </section>

        <!-- 合作流程 -->
        <section class="section d" v-if="a===3">
          <h2 :id="A.el">{{A.label}}</h2>

          <div class="imgs-box">
            <section class="imgs">
              <img class="img" v-for="i in 3" :key="i" :src="require(`@/assets/store/d_ps_${i}.png`)">
            </section>
            <!-- <img class="img-3" src="@/assets/store/d.png"> -->
          </div>

          <div class="img-box-2">
            <el-carousel :interval="4000" type="card" height="350px">
              <el-carousel-item v-for="i in 3" :key="i">
                <!-- <el-image style="height:100%;width:100%" :src="require(`@/assets/store/imgs/${i}.png`)" fit="cover"></el-image> -->
                <el-image style="height:100%;width:100%" :src="$hwcdnLink(`imagehost/vrhome_store/${i}.png`)" fit="cover"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>

          <section class="advisory">
            <div class="advisory-content">
              <div class="icon-box">
                <i class="iconfont icon-dianhua"></i>
              </div>
              <div class="info">
                <h3>合作咨询</h3>
                <p>联系人：张先生<br>电话：18759946965<br>微信：zamervr</p>
              </div>
              <div class="weixin">
                <img src="@/assets/store/qrcode.png" alt="" class="qrcode">
                <p class="tip">欢迎扫码咨询</p>
              </div>
            </div>
          </section>
        </section>

      </section>
    </main>

    <div class="float-img" :class="{show: floatShow}">
      <img class="img" src="@/assets/store/float.png">
      <div class="close" @click.stop="floatShow=false">
        <i class="iconfont icon-close"></i>
      </div>
      <div class="click-area" @click="navDetail"></div>
    </div>
    <div class="float-mini" :class="{show: !floatShow}">
      <img class="img" src="@/assets/store/float-mini.gif" @click="navDetail">
    </div>

    <partner color="white" />
    <page-footer class="footer" />
  </div>
</template>

<script>
import topCover from "@/components/TopCover";
import pageFooter from "@/components/Footer";
import partner from "@/components/Partner";
// import toc from "@/components/Toc";

import { Marquee, Slide } from "vue-marquee-component";

export default {
  components: {
    topCover,
    pageFooter,
    partner,
    // toc,
    [Marquee.name]: Marquee,
    [Slide.name]: Slide,
  },
  data() {
    return {
      tocItems: [
        {
          icon: "icon-fenxi",
          label: "超级品牌",
          el: "toc_social",
        },
        {
          icon: "icon-anli",
          label: "合作案例",
          el: "toc_mjcontent",
        },
        {
          icon: "icon-zhichibaozhangjigou",
          label: "支持保障",
          el: "toc_social",
        },
        {
          icon: "icon-liucheng",
          label: "合作流程",
          el: "toc_mjcontent",
        },
      ],

      sectionA: {
        items: [
          {
            img: require("@/assets/store/a_1.png"),
            bgColor: "#fff",
            titleColor: "#252527",
            contentColor: "#999999",
            title: "投资开店，选对行业是关键",
            content:
              "国家政策大力发展VR产业<br>娱乐性消费呈快速上升趋势<br>VR已经成为线下娱乐新潮方式<br>全国2.78亿VR潜在用户",
          },
          {
            img: require("@/assets/store/a_2.png"),
            bgColor: "#6157D0",
            title: "Quest无线大空间",
            content:
              "目前最火的VR新玩法，QUEST无线大空间支持6m*6m全空间定位，实现玩家真人在超大空间内自由行走，同步虚拟世界移动，真实近距离交互。",
          },
          {
            img: require("@/assets/store/a_3.png"),
            bgColor: "#F1C26E",
            title: "联机私密包厢",
            content:
              "联机私密包厢支持3m*6m情侣、亲子、基友、闺蜜等等一起畅玩游戏，1300款热门大作满足各类顾客需求，动作类游戏、休闲类游戏、联机类游戏、儿童类游戏等等，应有尽有~",
          },
        ],
      },
      sectionC: {
        current: 0,
        items: [
          {
            icon: "icon-kaidian",
            iconColor: "#0EDEE3",
            label: "快速营业",
            content:
              "多任务高效同步执行，最快15天可完成从选址装修、设备入场调试到正式开业",
          },
          {
            icon: "icon-peixun",
            iconColor: "#FEB47B",
            label: "全程指导",
            content:
              "我们拥有完善的技术和营销团队，从准备到开业到后期经营全程指导，系统化学习，包教包会，完全不用担心经营问题",
          },
          {
            icon: "icon-deposit",
            iconColor: "#F74C32",
            label: "赚钱三部曲",
            content:
              "引流：多渠道宣传吸引顾客进店<br>转化：低消顾客转化为高消经营策略<br>回流：裂变营销吸引更多顾客进店",
          },
          {
            icon: "icon-strategy",
            iconColor: "#6190E8",
            label: "经营策略",
            content: "门店日常化管理，店员系统化培训，打造标准化服务",
          },
          {
            icon: "icon-anli",
            iconColor: "#20BA7A",
            label: "经营系统",
            content: "“优店”门店管理系统<br>团购分销裂变系统",
          },
          {
            icon: "icon-24xiaoshi",
            iconColor: "#0EDEE3",
            label: "售后支持",
            content:
              "VR专业售后团队<br>7天*24小时待命<br>安装使用全程指导<br>疑难问题及时解决<br>对外营业安心无忧",
          },
        ],
        games: [
          "20170120171358894601_9069.jpg",
          "20170410114008853216_4520.jpg",
          "20170419112310431337_7433.jpg",
          "20170428194333790706_6595.jpg",
          "20170515160116431361_7706.jpg",
          "20170602171429306793_7428.jpg",
          "20170710162712587951_0444.jpg",
          "20170814180145337970_2200.jpg",
          "20171206175748884827_8405.jpg",
          "20171211112615494205_4444.jpg",
          "20171222173158041275_0217.jpg",
          "20171229171616338006_0244.jpg",
          "20180102172446166112_2613.jpg",
          "20180302171733277251_9152.jpg",
          "20180311192439292800_1223.jpg",
          "20180322143655558375_7710.jpg",
          "20180322143823386533_7210.jpg",
          "20181213173543256495_1627.jpg",
          "20181217162529945854_5924.jpg",
          "20190514150023076846_0137.jpg",
          "20190710162608014443_7367.jpg",
          "20190710162657420578_0987.jpg",
          "20190812121137120212_7231.jpg",
          "20191118183106354519_2855.jpg",
          "20200416185329244716_8157.jpg",
          "20200526152357088487_5304.jpg",
          "20200630110400729147_8533.jpg",
          "20200721093711449720_8217.jpg",
          "20200723122450809065_5121.jpg",
          "20200915145732699669_4828.jpg",
          "20200916141250371567_6736.jpg",
          "20201026174507277873_4474.jpg",
          "20201104162648277804_6708.jpg",
          "20180119095647794546_3287.png",
          "20181013120836754855_9578.png",
          "20181222100445381628_0892.png",
          "20190108161041553508_8269.png",
          "20190128134241394355_1735.png",
          "20190328190455998695_0806.png",
          "20190422190608217510_4390.png",
          "20190425095058936200_9771.png",
          "20190521095409998692_3494.png",
          "20190524141441530085_6454.png",
          "20190625150519420537_2369.png",
          "20190709140231279938_0637.png",
          "20190917150438417156_4276.png",
          "20191031151223276413_3236.png",
          "20191211101810588918_9001.png",
          "20200612142306635382_3813.png",
          "20200918113346637160_1006.png",
          "20201026174624793459_7421.png",
          "20201026174655168519_8669.png",
        ],
      },
      sectionD: {},
      floatShow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.floatShow = true;
    }, 1000);
  },
  beforeDestroy() {},
  methods: {
    navDetail() {
      // this.$router.push("/store/detail");
      window.open('/store/detail', '_blank')
    },
  },
};
</script>

<style lang="scss" scoped>
.page-mengjing {
  background-color: #262626;
}

main {
  width: 1080px;
  margin: 0 auto;

  h2 {
    width: 100vw;
    margin: 0 0 0 50%;
    transform: translateX(-50%);
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 30px;
    color: #0edee3;
    font-weight: lighter;
    background-color: #333333;
  }
}

.section.a {
  position: relative;

  h2 {
    margin-bottom: 100px;
  }
  .intro {
    font-size: 18px;
    color: rgba(#999, 0.8);
    line-height: 46px;
    margin: 0 0 66px;
  }
  .items {
    .item {
      width: 100%;
      display: flex;
      margin-bottom: 60px;
      .img {
        width: 500px;
        height: 300px;
        object-fit: cover;
        display: block;
      }
      .box {
        flex: 1;
        height: 300px;
        color: #fff;
        padding: 50px 60px;
        box-sizing: border-box;
        .title {
          font-size: 30px;
          line-height: 1;
          // text-align: center;
          margin: 0 0 46px;
          font-weight: normal;
        }
        .content {
          width: 100%;
          font-size: 18px;
          line-height: 36px;
          margin: 0;
        }
      }
    }
  }

  &::before {
    content: "";
    width: 160px;
    height: 160px;
    border: solid 35px #198285;
    border-radius: 50%;
    position: absolute;
    left: -470px;
    top: 240px;
  }
  &::after {
    content: "";
    width: 172px;
    height: 200px;
    position: absolute;
    right: -290px;
    top: 1058px;
    background-image: url("~@/assets/store/circle_2.png");
  }
}
.section.b {
  .form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 100px;
      margin-bottom: 60px;
      font-size: 18px;
      color: #0edee3;
      line-height: 1;
    }
    .form {
      width: 1610px;
      height: auto;
      display: block;
    }
  }
  .environment-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
    .environment {
      width: 1080px;
      height: auto;
      display: block;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}
.section.c {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  .tabs {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .item {
      width: 200px;
      height: 240px;
      border-radius: 20px;
      background-color: rgba(#fff, 0.05);
      overflow: hidden;
      position: relative;
      cursor: default;

      &:not(:last-child) {
        margin-right: 56px;
      }

      .wrapper {
        width: 100%;
        height: 200%;
        position: absolute;
        left: 0;
        top: 0;
        transition: transform 0.5s;

        &:hover {
          transform: translateY(-50%);
        }

        .block {
          width: 100%;
          height: 50%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &.a {
            .icon-box {
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 50px;
              .iconfont {
                font-size: 50px;
                opacity: 0.5;
              }
            }
            .label {
              font-size: 18px;
              color: rgba(#fff, 0.5);
              font-weight: normal;
              margin: 0;
            }
          }
          &.b {
            padding: 0 20px;
            font-size: 16px;
            color: rgba(#fff, 0.5);
            box-sizing: border-box;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .games-title {
    margin: 120px 0 0;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    color: rgba(#fff, 0.3);
  }
  .games-box {
    width: 100vw;
    height: 180px;
    margin: 100px 0;

    .game-item {
      width: 384px;
      height: 180px;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}
.section.d {
  .imgs-box {
    width: 1920px;
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .imgs {
      // margin-top: 100px;
      width: 100%;
      .img {
        width: 100%;
        display: block;
      }
    }
    .img-3 {
      width: 1080px;
      height: auto;
      display: block;
      margin-top: 100px;
    }
  }
  .advisory {
    margin-top: 80px;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 360px;

    .advisory-content {
      width: 1080px;
      height: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
      padding-top: 100px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;

      .icon-box {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: solid 3px #0edee3;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-dianhua {
          color: #0edee3;
          font-size: 70px;
        }
      }
      .info {
        margin: 0 280px 0 50px;
        h3 {
          font-size: 36px;
          line-height: 1;
          margin: 0;
          margin-bottom: 40px;
          font-weight: normal;
          color: #0edee3;
        }
        p {
          font-size: 18px;
          line-height: 36px;
          color: #0edee3;
          margin: 0;
        }
      }
      .weixin {
        .qrcode {
          width: 123px;
          height: 123px;
          display: block;
          margin-bottom: 24px;
        }
        .tip {
          font-size: 18px;
          color: #0edee3;
          text-align: center;
          margin: 0;
        }
      }
    }
  }
}

.float-img {
  width: 1920px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  z-index: 9999;
  transition: transform 0.5s;
  &.show {
    transform: translate(-50%, 0);
  }
  .img {
    width: 100%;
    height: auto;
    display: block;
  }
  .close {
    position: absolute;
    right: 50px;
    top: 50%;
    font-size: 30px;
    color: #fff;
    padding: 10px;
    line-height: 1;
    cursor: pointer;
  }
  .click-area {
    width: 244px;
    height: 54px;
    position: absolute;
    right: 308px;
    bottom: 52px;
    cursor: pointer;
  }
}
.float-mini {
  position: fixed;
  right: 0px;
  bottom: 100px;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.5s;
  cursor: pointer;

  &.show {
    transform: translateX(0);
  }

  .img {
    height: 50px;
    border-radius: 100px 0 0 100px;
  }
}
</style>